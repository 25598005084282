body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  box-sizing: border-box;
  background-color: #EEF1F5;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol, ul, a {
  list-style: none;
  text-decoration: none;
}

a {
color: #717806;
}

p, h1, h2, h3, div {
  margin: 0;
  padding: 0;
}

.selected {
color: white;
text-decoration: none;
background-color: #3A4042;
border-right: 4px solid #BAC108;
font-weight: 700;
}
